import '../styles/main.scss';

// NAJA
import naja from 'naja';

// Components
import './components/oembed';
import './components/lazy-load';
import './components/main-menu';

// Nette Forms
import netteForms from 'nette-forms';

import onDomReady from './onDomReady';

window.Nette = netteForms;
netteForms.initOnLoad();

// Naja init
naja.initialize();

//import netteForms from 'nette-forms';
//
//// Naja init
//naja.formsHandler.netteForms = netteForms;
//window.addEventListener('load', naja.initialize.bind(naja), false);

// LightBox
import GLightbox from 'glightbox';

window.GLightbox = new GLightbox({
	touchNavigation: true,
});

naja.addEventListener('start', () => {
	document.body.classList.add('is-loading');
});

naja.addEventListener('complete', () => {
	document.body.classList.remove('is-loading');
});

let scrolledY = window.scrollY;
let parallax = document.querySelectorAll('.parallax');
parallax.forEach(function (el) {
	updateParallax(el, scrolledY);
});

document.addEventListener('scroll', function () {
	let scrolledY = window.scrollY;
	let parallax = document.querySelectorAll('.parallax');
	parallax.forEach(function (el) {
		updateParallax(el, scrolledY);
	});
});

function updateParallax(el, scrolledY) {
	let parallaxStart = el.offsetTop;
	let windowHeight = window.innerHeight;
	let img = el.querySelector('.parallax-img');

	if (scrolledY + windowHeight > parallaxStart) {
		let parallaxOffset = scrolledY - parallaxStart;
		img.style.transform = 'translateY(' + parallaxOffset * 0.5 + 'px)';
	}
}

const slideToElement = hash => {
	hash = hash.replace('#', '');
	const targetElement = document.getElementById(hash);
	const masthead = document.getElementById('masthead');

	if (targetElement && masthead) {
		const mastheadHeight = masthead.clientHeight;
		const elementPosition =
			targetElement.getBoundingClientRect().top + window.pageYOffset;
		const offsetPosition = elementPosition - mastheadHeight;

		window.scrollTo({
			top: offsetPosition,
			behavior: 'smooth',
		});
	}
};

document.querySelectorAll('a[href^="#"]').forEach(link => {
	link.addEventListener('click', function (event) {
		if (this.hash) {
			slideToElement(this.hash);
			event.preventDefault();
		}
	});
});

document.querySelectorAll('.hero-subtitle').forEach(el => {
	el.innerHTML = el.textContent.replace(
		/(.+)\s+(\S+)\s+(\S+)$/,
		'$1\u00A0$2\u00A0$3',
	);
});

onDomReady(() => {
	let requiredInputs = document.querySelectorAll('input[required]');

	requiredInputs.forEach(function (input) {
		let id = input.id;
		if (id) {
			let label = document.querySelector('label[for="' + id + '"]');
			if (label) {
				label.innerHTML += ' <span style="color: #fb5271;">*</span>';
			}
		}
	});

	document.querySelectorAll('input[type=password]').forEach(el => {
		el.parentElement.style.position = 'relative';
		el.insertAdjacentHTML(
			'afterend',
			'<span class="input-password-eye"></span>',
		);
	});

	const inputPasswordEye = document.querySelectorAll('.input-password-eye');
	inputPasswordEye.forEach(el => {
		el.addEventListener('click', e => {
			const input = e.target.previousElementSibling;
			if (input.type === 'password') {
				input.type = 'text';
			} else {
				input.type = 'password';
			}
		});
	});
});
